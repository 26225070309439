<template>
  <graph
    class="dispute-open-reason"
    v-if="account && openReasons.length"
    width="100%"
    height="175px"
    :options="chartOptions"
    :series="series"
  />
</template>
<script>
export default {
  components: {
    graph: () => import('@/components/graph')
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          type: 'bar'
        },
        dataLabels: {
          enabled: true,
          formatter: v => this.formatCurrency(v, this.account.currency),
          offsetY: -20
        },
        labels: this.labels,
        legend: {
          show: false
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        xaxis: {
          type: 'string',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          show: false
        },
        title: {
          text: this.$t('t.OpenDisputesReasons')
        },
        subtitle: {
          text: this.$t('t.Amount') + '/' + this.$t('t.Balance')
        },
        tooltip: {
          enabled: false,
          intersect: false
        }
      }
    },
    labels () {
      return this.openReasons.map(v => v.text)
    },
    openReasons () {
      return this.account?.disputes?.openReasons || []
    },
    series () {
      return [
        { data: this.openReasons.map(v => v.amount) },
        { data: this.openReasons.map(v => v.balance) }
      ]
    }
  },
  props: {
    account: Object
  }
}
</script>
<style lang="stylus" scoped>
.dispute
  display flex
</style>
